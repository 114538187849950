import React, {useCallback, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {store} from "store";
import {addDays, format, getMonth} from "date-fns";
import {byDate as eventsByDate, deleteOne, groupSubmissions} from "../api";
import Loading from "molcules/Loading/Loading";
import {EventList} from "./EventsList";
import differenceInDays from "date-fns/differenceInCalendarDays/index";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EventsForm from "../EventsForm";
import CalendarDateSelect from "../../molcules/CalendarDateSelect/CalendarDateSelect";
import DeleteDialog from "../../molcules/Dialogs/DeleteDialog";
import {useQuery, useQueryClient} from "react-query";

const dayDateLabel = (inDate) => {
  return format(inDate, 'EEE, do MMM');
}

let fetchEvents = ({siteID, setState, dateRange, filters, urlQuery, groupsProfiles}) => {
  const updateTotals = (totalsDic, dateLabel, event) => {
    totalsDic[dateLabel] = totalsDic[dateLabel] || {
      kite: 0,
      events: 0,
      arrivals: 0,
      departures: 0,
      sleepers: 0,
      dayGuests: 0,
      extraActual: 0,
      extraForecast: 0,
      totalPrice: 0
    };

    totalsDic[dateLabel].events += 1;
    totalsDic[dateLabel].kite += parseInt(event.kite || 0);
    totalsDic[dateLabel].arrivals += parseInt(event.arrivals || 0);
    totalsDic[dateLabel].departures += parseInt(event.departures || 0);
    totalsDic[dateLabel].sleepers += parseInt(event.sleepers);
    totalsDic[dateLabel].dayGuests += parseInt(event.dayGuests);
    totalsDic[dateLabel].extraForecast += event.extraForecast;
    totalsDic[dateLabel].totalPrice += event.totalPrice;
  }

  const calculateKiteProfiles = (eventVal, groupProfiles) => {
    groupProfiles.forEach(group => {
      if (group._id.eventId === eventVal._id) {
        eventVal.kite = group.count;
      }
    })
  }

  return eventsByDate({
    from: format(dateRange[0], 'yyyy-MM-dd'),
    to: format(dateRange[1], 'yyyy-MM-dd'),
    site: siteID
  }).then(data => {
    let countOfProfiles = groupsProfiles.data;
    let dayDistance = differenceInDays(dateRange[1], dateRange[0]) + 1;
    const days = [...Array(dayDistance).keys()].map((val, index) => addDays(dateRange[0], index));
    let dateLabelDict = {};
    let dateLabelTotals = {};
    let events = data.data;
    if (events) {
      /** Build out all the days inbetween */
      days.forEach(day => {
        const dateLabel = dayDateLabel(day);
        dateLabelDict[dateLabel] = [];
      });

      data.data.map(event => {
        const startDate = new Date(event.startDate);
        let dayDistance = differenceInDays(new Date(event.endDate), startDate) + 1;
        // ignore negative dayDistance
        if (dayDistance > 0) {
          [...Array(dayDistance).keys()].forEach((val, index) => {
            const dateLabel = dayDateLabel(addDays(startDate, index));
            if (index === 0) {
              updateTotals(dateLabelTotals, dateLabel, event);
              calculateKiteProfiles(event, countOfProfiles);
            }
            if(dateLabelDict[dateLabel]) {
              dateLabelDict[dateLabel].push({...event, start: index === 0, end: index === dayDistance-1, dayDistance: dayDistance, day: index});
            }
          });  
        }
        return event;
      });

      setState((prev) => {
        const events = Object.keys(dateLabelDict).map((key, index) => ({
          date: days[index],
          label: key,
          events: dateLabelDict[key],
          summary: dateLabelTotals[key],
          month: getMonth(days[index]),
          inCurrentMonth: getMonth(days[index]) === parseInt(urlQuery.month)
        }));
        return {
          ...prev,
          staffEditOpen: false,
          events: events
        }
      });
      return data.data;
    }
  });
}


export default function EventsTimeline() {
  const queryClient = useQueryClient();
  const {state: appState} = useContext(store);
  let { mode } = useParams();
  const [dateRange, setDateRange] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(false);
  const [filters] = useState({period: 'Month'});
  const [state, setState] = useState({
    staffEditOpen: false,
    create:false,
    selectedStaff:{},
    events: []
  });

  const {data: groupsProfiles} = useQuery([
      'groups',
      appState.site._id,
      dateRange,
      filters,
      appState.urlQuery
    ],
    () => groupSubmissions({
      site: appState.site._id,
      group: 'eventId',
      fields: 'count'
    }),
    {
      enabled: !!dateRange && !!appState?.site?._id,
      refetchOnWindowFocus: false
    }
  );

  const {isLoading = true} = useQuery([
    'eventsTimeline',
    appState.site._id,
    dateRange,
    filters,
    appState.urlQuery,
    groupsProfiles
  ],
    () => fetchEvents({
    siteID: appState.site._id,
    setState: setState,
    dateRange: dateRange,
    urlQuery: appState.urlQuery,
    filters: filters,
    groupsProfiles: groupsProfiles
  }),
    {
      enabled: !!dateRange && !!appState?.site?._id && !!groupsProfiles,
      refetchOnWindowFocus: false
    }
  );

  const handleCloseStaffEdit = () => {
    queryClient.invalidateQueries(['eventsTimeline']);
  };

  const datesChanged = useCallback(
    (newDates) => {
      setDateRange(newDates);
    },[]
  );

  const handleDelete = (e, event) => {
    if(e.stopPropagation) {
      e.stopPropagation();
    }
    e.preventDefault();
    setShowDelete(true);
    setSelected(event);
  };

  const deleteShift = () => {
    deleteOne(selected._id).then(() => {
      queryClient.invalidateQueries(['eventsTimeline']);
      setShowDelete(false);
    });
  }

  return (<div>
      <div className="heading">
        <span className="heading-text">Business on the Books</span>
        <div style={{float:'right'}}>
          <Button variant="outlined" color="primary" onClick={() => {
            setState(prev => {
              return {...prev, create: true, staffEditOpen: true}
            });
          }} startIcon={<AddCircleOutlineIcon />}>Add Event</Button>
        </div>
      </div>

      <CalendarDateSelect onChange={datesChanged} />

      {isLoading && <Loading/> }

      <div style={{paddingTop: 4}} >
        {!isLoading &&
          <React.Fragment>
            <DeleteDialog
              heading={'Are you sure you want to delete this event?'}
              show={showDelete}
              onDelete={deleteShift}
              onClose={() => setShowDelete(false)}
            />
            <EventList mode={mode || 'week'} events={state.events}
                       onDelete={handleDelete}
                       edit={(eventData) => {
                         setState(prev => ({...prev, selectedStaff: eventData, staffEditOpen: true, create:false}))
                       }}/>
          </React.Fragment>
          }
      </div>

      <EventsForm open={state.staffEditOpen}
                  user={state.selectedStaff}
                  create={state.create}
                  onClose={handleCloseStaffEdit} />

    </div>
  );
}
