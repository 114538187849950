import React from "react";
import {useQuery} from "react-query";
import Paper from "@material-ui/core/Paper";
import MyTable from "atoms/MyTable";
import {format} from "date-fns";
import {getRange} from "../Reservations/api";

const tableSettings = {
  columns: [
    { title: 'Booking Time', align:'left', field: 'createdAt', format:val => format(new Date(val), 'HH:mm do MMM')},
    { title: 'Name', align:'right', field: 'name'},
    { title: 'Group', align:'right', field: 'group'},
    { title: 'Space', align:'right', field: 'space'},
    { title: 'Submissions', align:'right', field: 'submissions', format:val => val.length || 0},
  ]
};

export const ReservationsByDate = ({siteId}) => {
  const dateRange = [new Date('2023-03-01'), new Date('2023-04-30')];
  const {isLoading = true, data} = useQuery(
    ['visits', siteId],
    () => getRange({
      siteId: siteId,
      from: format(dateRange[0], 'yyyy-MM-dd'),
      to: format(dateRange[1], 'yyyy-MM-dd'),
      period: 'date',
      dateTime: 'createdAt',
    })
  );
  let reservations = data ? data.data : [];
  reservations = reservations.filter((current, index, array) => {
    const firstIndex = array.findIndex((item) => item.group === current.group);
    const isDuplicate = firstIndex !== index;
    if (isDuplicate) {
      array[firstIndex].price += current.price;
      array[firstIndex].space += ', '+current.space;
    }
    return !isDuplicate;
  });

  return (
    <div style={{paddingTop:14}}>
      {!!reservations.length && !isLoading &&
        <Paper className={'paper'}>
          <h2>Reservation By Booking Date</h2>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            data={reservations}
            initialOrder={{orderBy:'createdAt', order:'desc'}}
          />
        </Paper>
      }
    </div>
  );
}
