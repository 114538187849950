import React, {useContext, useEffect, useState} from 'react';
import Alert from "@material-ui/lab/Alert";
import {Formik} from "formik";
import {create as createStaff, update} from "./api";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {store} from "../store";
import {formStyles} from "../atoms/Styles/styles";
import {FormElement, newFormModel} from "../molcules/forms";

export default function StaffForm({user, open, onClose, create=false}) {
  const classes = formStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [shape, setShape] = useState(false);
  const {state} = useContext(store);

  useEffect(() => {
    if (state.site._id) {
      const positions = (state.site.positions || []).map(value => ({title: value}));
      setShape({
        email: {type: "text", id: "email", label: "Email", default: '', validation: yup.string().required('Email is required').email()},
        firstName: {type: "text", id: "firstName", label: "First Name", default: '', validation: yup.string().required('First Name is required')},
        lastName: {type: "text", id: "lastName", label: "Last Name", default: '', validation: yup.string().required('Last Name is required')},
        pin: {type: "text", id: "pin", label: "Pin", default: '', validation: yup.string().min(4)},
        rate: {type: "text", id: "rate", label: "Rate", default: '', validation: yup.string().nullable()},
        salary: {type: "text", id: "salary", label: "Salary", default: '', validation: yup.string().nullable()},
        password: {default: '', hidden: true},
        sites: {default: '', hidden: true},
        role: {default: '', hidden: true},
        position: {type: "select", selectValues: [...positions,
            { title: 'Other' }
          ], id: "position", label: "Position", default: ''},
        offRota: {type: "boolean", id: "offRota", label: "Hide from Rota", default: false},
        suspendAccount: {type: "boolean", id: "suspendAccount", label: "Suspend Account (staff member will not be able to login)", default: false}
      });
    }
  }, [state.site]);

  return (
    <div>
      {shape && <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <Formik
          initialValues={create ?
            newFormModel({shape: shape}) :
            newFormModel({shape: shape, data: user})}
          onSubmit={(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            setSubmitFailed(false);
            if (create) {
              createStaff(newFormModel(
                {shape: shape,
                  data: {...values, role: 'staff', password:'rhtemp23',
                    sites: state.site.normalName
                  }}))
                .then(() => {
                  setSubmitting(false);
                  onClose();
                }).catch((e) => {
                setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                setSubmitFailed(true);
                setSubmitting(false);
              });
            } else {
              update(user._id, newFormModel({shape: shape, data: values})).then(() => {
                setSubmitting(false);
                onClose();
              }).catch((e) => {
                setErrors({ api: e.errorArray ? e.errorArray : ['update failed'] });
                setSubmitFailed(true);
                setSubmitting(false);
              });
            }
          }}

          validationSchema={yup.object().shape(
            newFormModel({shape:shape, validation: true}))
          }
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form autoComplete="off" onSubmit={handleSubmit} className={classes.modalForm}>
                {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to {create ? <span>create</span> : <span>update</span>} user.</Alert> : null}
                {errors.api && errors.api.length && <Alert severity="error"><span className={'error'}>*Server errors, </span>
                  {errors.api.map(err => (
                    <span key={err}>{err}</span>)
                  )}</Alert>}
                <div id="max-width-dialog-title" className={classes.modalFormHead}><h2
                  className={classes.modalFormH2}>{create ? <span>Add Staff</span> : <span>Update Staff</span>}</h2></div>

                <div className={classes.modalFormContent}>
                  <div>
                    <div>
                      {Object.values(shape).map((formConfig) => (
                        <FormElement
                          key={formConfig.id}
                          id={formConfig.id}
                          label={formConfig.label}
                          type={formConfig.type}
                          hidden={formConfig.hidden}
                          selectValues={formConfig.selectValues}
                          values={values}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`${classes.formSubmit} ${classes.modalFormFoot}`}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setSubmitFailed(false);
                      onClose();
                    }}
                  >Cancel
                  </Button>
                  <Button
                    style={{marginLeft: 8}}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary">{create ? <span>Add</span> : <span>Update</span>}
                  </Button>
                </div>
              </form>);
          }}
        </Formik>
      </Dialog>}
    </div>
  );
}
